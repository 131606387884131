:root {
  --accent-color: #5DBCD7;
}

.menuMain img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.menuFooter {
  font-size: 14px;
  font-weight: 400;
  color: lightgray;
  text-align: center;
  margin: 0px;
  margin-left: 15px;
  padding: 20px 0px 100px 0px;
}

.menuMain {
  align-content: center;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.categoryHeader {
  font-size: 20px;
  font-weight: 600;
  color: black;
  text-align: left;
  margin: 0px;
  margin-left: 15px;
  padding: 15px 0px 10px 0px;
}

.searchHeader {
  display: flex;
  flex-direction: row;
  margin: 5px 15px;
  justify-content: center;
  align-items: center;
}

.secondaryText {
  font-size: 12px;
  font-weight: 400;
  color: gray;
  text-align: left;
  margin: 0px 15px 0px 15px;
  padding: 0px;
}

.bottom-sheet {
  position: fixed;
  bottom: -500px;
  left: 0;
  right: 0;
  height: 500px;
  background-color: white;
  border-radius: 20px 20px 0px 0px;
  z-index: 1000;
  transition: bottom 0.3s ease-in-out;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);
}

.bottom-sheet.show {
  bottom: 0;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}
.overlay.show {
  pointer-events: auto;
  opacity: 1;
}

.menuMainHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0px 15px 15px 15px;
  width: calc(100% - 30px);
  box-sizing: border-box;
  align-items: center;
}

.menuMainHeader h1 {
  font-size: 30px;
  font-weight: 600;
  color: black;
  text-align: left;
  margin: 10px 0px;
  padding: 0px;
  flex-grow: 1;
}
.truncate-text { 
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}

.changeLayoutButton {
  text-decoration: none;
  border-width: 0;
  height: 30px;
  background: none;
  color: var(--accent-color);
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  -moz-focus-inner: 0;
}

.plainLink {
  text-decoration: none;
  color: black;
}

.dividerHorizontal {
  height: 0.3px;
  width: 100%;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

.menuStickyHeader {
  background: white;
  position: sticky;
  top: 0;
  padding: 5px 0px 0px 0px;
  z-index: 10;
}

.categoryButtons {
  overflow-y: hidden;
  overflow-x: scroll;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 0px 15px;
  margin: 10px 0px 0px 0px;
  position: sticky;
  display: flex;
}

.searchBox {
  font-size: 16px;
  padding: 10px 0px 10px 12px;
  color: black;
  text-decoration: none;
  border: 0;
  border-radius: 8px;
  background: #f5f5f7;
  margin: 0px 0px 0px 0px;
  width: calc(100% - 30px);
  box-sizing: border-box;
}

.addressBox {
  font-size: 16px;
  padding: 10px 0px 10px 12px;
  color: black;
  text-decoration: none;
  border: 0;
  border-radius: 8px;
  background: #f5f5f7;
  margin: 0px 0px 0px 0px;
  width: calc(100% - 30px);
  box-sizing: border-box;
}

.searchBox:focus {
  outline: none;
}

.addressBox:focus {
  outline: none;
}

.categoryButtons::-webkit-scrollbar {
  display: none;
}

.categoryButton {
  font-size: 18px;
  text-decoration: none;
  font-weight: 600;
  color: white;
  background-color: var(--accent-color);;
  border-radius: 8px;
  background-clip: padding-box;
  margin-right: 10px;
  padding: 8px 12px;
}

.secondaryButtonStyle {
  font-size: 12px;
  text-decoration: none;
  font-weight: 500;
  color: white;
  background-color: var(--accent-color);;
  border-radius: 15px;
  border: 0;
  background-clip: padding-box;
  margin-right: 10px;
  padding: 4px 10px;
}

.toggle-container {
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 25px;
  background-color: #f1f1f1;
  overflow: hidden;
}

.slider {
  position: absolute;
  width: 50%;
  height: 100%;
  padding: 0px 0px;
  margin: 0px 0px;
  background-color: var(--accent-color);;
  color: white;
  border-radius: 25px;
  transition: transform 0.3s ease;
}

.toggle-option {
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 500;
  flex-grow: 1;
  text-align: center;
  z-index: 1;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  -moz-focus-inner: 0;
  border: 0;
  outline: none;
  margin: 10px;
}

.hstackMimic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 0px 0px;
  padding: 0px;
}

.hidden {
  display: none;
}

.primaryButtonBox {
  background-color: var(--accent-color);;
  border-radius: 10px;
  margin: 0px 0px 0px 0px;
  width: calc(100% - 30px);
  box-sizing: content-box;
}

.primaryButton {
  font-weight: 500;
  font-size: 16px;
  padding: 15px;
  color: white;
  border: 0;
  box-sizing: border-box;
}

.verification-container {
  display: flex;
  justify-content: flex-start;
}

.verification-container input {
  padding: 0;
  text-decoration: none;
  margin: 0px 10px 0px 0px;
  width: 10vw;
  height: 10vw;
  text-align: center;
  font-size: 22px;
  border: 2px solid transparent;
  background-color: #f5f5f7;
  border-radius: 8px;
  outline: none;
}

.verification-container input:focus {

  border-color: var(--accent-color);;
}

.loading-indicator {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3); /* Light circle */
  border-radius: 50%;
  border-top: 3px solid var(--accent-color); /* Dark circle portion */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.errorText {
  font-size: 12px;
  font-weight: 400;
  color: red;
  text-align: left;
  margin: 0px 15px 0px 15px;
  padding: 0px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}